import React from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import Container from '../../ui/Grid/Container';
import * as styles from './styles.module.scss';

type VideoTeaserProps = {
  videoId: string;
  videoTitle: string;
};

const VideoTeaser: React.FC<VideoTeaserProps> = ({ videoId, videoTitle }) => {
  return (
    <Container additionalClass={styles.container}>
      <LiteYouTubeEmbed
        id={videoId}
        title={videoTitle}
        params="rel=0"
        wrapperClass="yt-lite rounded-3xl"
        poster="maxresdefault"
      />
    </Container>
  );
};

export default VideoTeaser;
